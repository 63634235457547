import virginia from './os/North_America/virginia';

const data = {
	title: 'Managed AWS Compute',
	seoDescription:
		'Scalable, AWS EC2 virtual machines with globally available compute power for high workloads and every budget.',
	helpText:
		'AWS Public Cloud offers finely-tuned CPU, RAM and SSD storage resources for unsurpassable performance.',
	oses: [
		{
			name: 'Default Datacenter',
			items: [virginia],
		},
	],
	stepOneTitle: 'Server location',
	stepTwoTitle: 'Pick your application',
	stepThreeTitle: 'Choose a cloud server',
	footerSummary: '{{Size}} — {{App}} — AWS {{OS}}',
	banner: '<span class="news-label">Help? ',
	alertIcon: 'cloud',
	alertTitle: 'Fully-Managed AWS Elastic Compute Cloud (EC2)',
	alertMessage: 'Laravel, LAMP, Nginx, Jupyter and Django stacks, configured on EC2. Free linux server set up, console, key management, firewall, root access. The virtual machine emails your database credentials, the instant Amazon assigns static IP.',
};

export default data;
