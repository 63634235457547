const pricing = [
	{
		id: '40GB-laravel',
		title: '40GB SSD',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails Laravel database credentials after 15 min installation', 'Static Amazon IPv4', 'Up-to-date Versioning',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 3 hours after you update A Record', '40GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '2TB Premium Bandwidth', '1GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '60GB-laravel',
		title: '60GB SSD',
		price: '$27.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails Laravel database credentials after 15 min installation', 'Static Amazon IPv4', 'Up-to-date Versioning',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 3 hours after you update A Record', '60GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '3TB Premium Bandwidth', '2GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '80GB-laravel',
		title: '80GB SSD',
		price: '$39.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails Laravel database credentials after 15 min installation', 'Static Amazon IPv4', 'Up-to-date Versioning',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 3 hours after you update A Record', '80GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '4TB Premium Bandwidth', '4GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '160GB-laravel',
		title: '160GB SSD',
		price: '$59.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails Laravel database credentials after 15 min installation', 'Static Amazon IPv4', 'Up-to-date WordPress',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 3 hours after you update A Record', '160GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '5TB Premium Bandwidth', '8GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management','phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '40GB-jupyter',
		title: '40GB SSD',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails Jupyter login credentials after 15 min installation', 'Static Amazon IPv4', 'Up-to-date Versioning',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 1 hour after you update A Record', '40GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '2TB Premium Bandwidth', '1GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '60GB-jupyter',
		title: '60GB SSD',
		price: '$27.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails Jupyter login credentials after 15 min installation', 'Static Amazon IPv4', 'Up-to-date Versioning',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 1 hour after you update A Record', '60GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '3TB Premium Bandwidth', '2GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '80GB-jupyter',
		title: '80GB SSD',
		price: '$39.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails Jupyter login credentials after 15 min installation', 'Static Amazon IPv4', 'Up-to-date Versioning',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 1 hour after you update A Record', '80GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '4TB Premium Bandwidth', '4GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '160GB-jupyter',
		title: '160GB SSD',
		price: '$59.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server automatically emails Jupyter login credentials after 15 min installation', 'Static Amazon IPv4', 'Up-to-date WordPress',  'Set-up on your custom domain', 'Free Auto-renewing SSL issued 1 hour after you update A Record', '160GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '5TB Premium Bandwidth', '8GB Guaranteed RAM', 'White Label', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management','phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '40GB-php',
		title: '40GB SSD',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server Setup', 'White Label', 'Latest versions of PHP, PHP main modules, Apache, MariaDB', 'Includes Composer', '40GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '2TB Premium Bandwidth', '1GB Guaranteed RAM', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '60GB-php',
		title: '60GB SSD',
		price: '$27.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server Setup', 'White Label', 'Latest versions of PHP, PHP main modules, Apache, MariaDB', 'Includes Composer', '60GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '3TB Premium Bandwidth', '2GB Guaranteed RAM', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '80GB-php',
		title: '80GB SSD',
		price: '$39.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server Setup', 'White Label', 'Latest versions of PHP, PHP main modules, Apache, MariaDB', 'Includes Composer', '80GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '4TB Premium Bandwidth', '4GB Guaranteed RAM', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '160GB-php',
		title: '160GB SSD',
		price: '$59.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server Setup', 'White Label', 'Latest versions of PHP, PHP main modules, Apache, MariaDB', 'Includes Composer', '160GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '5TB Premium Bandwidth', '8GB Guaranteed RAM', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management','phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '40GB',
		title: '40GB SSD',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server Setup', 'White Label', '40GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '2TB Premium Bandwidth', '1GB Guaranteed RAM', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '60GB',
		title: '60GB SSD',
		price: '$27.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server Setup', 'White Label', '60GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '3TB Premium Bandwidth', '2GB Guaranteed RAM', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '80GB',
		title: '80GB SSD',
		price: '$39.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server Setup', 'White Label', '80GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '4TB Premium Bandwidth', '4GB Guaranteed RAM', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management', 'phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
	{
		id: '160GB',
		title: '160GB SSD',
		price: '$59.95 /mo',
		banner: 'AWS',
		features: ['Managed by admins for you with 24/7 support', 'Server Setup', 'White Label', '160GB AWS Elastic Block SSD Volume Storage', '2 Cores AWS Xeon® CPU', '5TB Premium Bandwidth', '8GB Guaranteed RAM', 'Monitoring Panel', 'Full Root Access', 'Full SSH Access', 'Secure Key Management','phpMyAdmin with local host access (127.0.0.1)', 'Firewall Options', 'Snapshot Options', 'Automated Backup Options', 'Static Amazon IPv4', '99.9% Uptime SLA'],
		rrp: 'FULLY MANAGED',
	},
];

export default pricing;
