import React, { useState } from 'react';
import styled from 'styled-components';

import { navigate } from 'gatsby';
import { iconMaps } from '../SiteNav';
import ButtonLink from '../ButtonLink';

const AlertWrap = styled.div`
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	position: relative;
	padding: 16px;
	margin: 0 0 ${props => props.theme.gutter.small}px 0;
	border: 1px solid ${props => props.theme.primary.border};
	background: ${props => props.theme.primary.background};
	border-radius: 2px;
	align-items: center;
	@media ${props => props.theme.breakpoints.tabletQuery} {
		margin-bottom: ${props => props.theme.gutter.large}px;
		padding: 16px 24px;
	}
`;

const AlertIcon = styled.div`
	display: none;
	@media ${props => props.theme.breakpoints.tabletQuery} {
		display: block;
		width: 112px;
		height: 112px;
		flex: 0 0 112px;
		margin: 0 20px 0 0;
		svg {
			height: 112px;
			width: 112px;
		}
	}
`;

const AlertBody = styled.div`
	flex: 0 0 100%;
	@media ${props => props.theme.breakpoints.tabletQuery} {
		flex-basis: calc(100% - 132px); /* 112px svg and 20px margin */
		margin: 20px 0;
	}
`;

const AlertTitle = styled.h4`
	margin: 0 0 16px 0;
	line-height: 1.2;
	font-size: 24px;
	color: ${props => props.theme.neutrals.darker};
	font-weight: 800;
`;

const AlertMessage = styled.div`
	font-size: 18px;
	line-height: 1.8;
	color: ${props => props.theme.neutrals.secondaryText};
`;

const Button = styled.button`
	height: 48px;
	font-size: 1.125rem;
	line-height: 46px;
	border-color: #e0452f;
	color: #fff;
	text-shadow: 0 -1px 1px ${props => props.theme.primary.darkest};
	display: inline-block;
	padding: 0 16px;
	border-radius: 5px;
	font-family: inherit;
	font-weight: 700;
	line-height: 36px;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	vertical-align: middle;
	touch-action: manipulation;
	border: 1px solid #b8b8b8;
	background: ${props => props.theme.primary.dark}
		linear-gradient(
			${props => props.theme.primary.light},
			${props => props.theme.primary.dark}
		);
	&:hover {
		text-decoration: none;
		background: ${props => props.theme.primary.dark}
			linear-gradient(
				${props => props.theme.primary.light},
				${props => props.theme.primary.dark}
			);
	}
	&:active {
		background: ${props => props.theme.primary.dark}
			linear-gradient(
				${props => props.theme.primary.light},
				${props => props.theme.primary.dark}
			);
	}
	&:focus {
		border-color: #75b9f0;
		outline: none;
		box-shadow: 0 0 8px #97cbf4;
	}

	@media screen and (max-width: 576px) {
		margin-top: 12px;
	}
`;
const AlertDismissButton = styled.button`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 4px;
	right: 4px;
	height: 24px;
	width: 24px;
	font-size: 22px;
	text-align: center;
	line-height: 1;
	color: ${props => props.theme.neutrals.secondaryText};
	background: transparent;
	border: 0 none;
	padding: 0;
	margin: 0;
	cursor: pointer;
	&:hover {
		color: ${props => props.theme.neutrals.darker};
	}
	@media ${props => props.theme.breakpoints.tabletQuery} {
		font-size: 28px;
		height: 32px;
		width: 32px;
	}
`;

const FlexWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: 576px) {
		display: block;
	}
`;

const ButtonWrapper = styled.div`
	width: 260px;
	cursor: pointer;

	a {
		padding: 0;
	}

	@media screen and (max-width: 576px) {
		width: 100%;
		margin-top: 10px;
	}
`;

export default function Alert({ icon, title, message, isHosting }) {
	const [hide, setHide] = useState(false);
	if (hide) {
		return null;
	}
	return (
		<AlertWrap>
			<AlertDismissButton
				type="button"
				onClick={e => {
					e.preventDefault();
					setHide(true);
				}}
			>
				&times;
			</AlertDismissButton>
			<AlertIcon>{iconMaps[icon]}</AlertIcon>
			<AlertBody>
				<AlertTitle>{title}</AlertTitle>
				<FlexWrapper>
					<AlertMessage
						dangerouslySetInnerHTML={{ __html: message }}
					/>
					{isHosting && (
						<ButtonWrapper
							onClick={() => navigate('/vps-hosting/#compare-plans')}
						>
							<ButtonLink
								backgroundBlue
								onClick={e => e.preventDefault()}
							>
								Compare plans
							</ButtonLink>
						</ButtonWrapper>
					)}
				</FlexWrapper>
			</AlertBody>
		</AlertWrap>
	);
}
