export function findBy(arr, key, value) {
	return arr.find(a => a[key] === value);
}

export function formatString(
	str,
	{ osName = '', appName = '', serverSize = '', serverPrice = '' }
) {
	return str
		.replace(/{{OS}}/g, osName)
		.replace(/{{App}}/g, appName)
		.replace(/{{Size}}/g, serverSize)
		.replace(/{{Price}}/g, serverPrice);
}
