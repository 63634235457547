const data = {
	name: 'US East (N. Virginia)',
	icon: 'Arabic',
	versions: [
		{
			name: 'Review later in cart',
			applications: [
				{
					id: 'laravel',
					pricings: [				
						{
							id: '40GB-laravel',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/laravel-40',
						},
						{
							id: '60GB-laravel',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/laravel-60',
						},
						{
							id: '80GB-laravel',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/laravel-80',
						},
						{
							id: '160GB-laravel',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/laravel-160',
						},
					],
				},
				{
					id: 'jupyter',
					pricings: [				
						{
							id: '40GB-jupyter',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/jupyter-notebook-hosting-40',
						},
						{
							id: '60GB-jupyter',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/jupyter-notebook-hosting-60',
						},
						{
							id: '80GB-jupyter',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/jupyter-notebook-hosting-80',
						},
						{
							id: '160GB-jupyter',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/jupyter-notebook-hosting-160',
						},
					],
				},
				{
					id: 'php',
					pricings: [				
						{
							id: '40GB-php',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/php-hosting-40',
						},
						{
							id: '60GB-php',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/php-hosting-60',
						},
						{
							id: '80GB-php',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/php-hosting-80',
						},
						{
							id: '160GB-php',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/php-hosting-160',
						},
					],
				},
				{
					id: 'nginx',
					pricings: [				
						{
							id: '40GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/nginx-hosting-40GB',
						},
						{
							id: '60GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/nginx-hosting-60GB',
						},
						{
							id: '80GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/nginx-hosting-80GB',
						},
						{
							id: '160GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/nginx-hosting-160GB',
						},
					],
				},
				{
					id: 'django',
					pricings: [				
						{
							id: '40GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/django-hosting-40',
						},
						{
							id: '60GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/django-hosting-60',
						},
						{
							id: '80GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/django-hosting-80',
						},
						{
							id: '160GB',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/django-hosting-160',
						},
					],
				},
			],
		},
	],
};

export default data;