const applications = [
	{
		id: 'jupyter',
		name: 'Jupyter Notebook',
		icon: 'Vector',
	},
	{
		id: 'laravel',
		name: 'Laravel',
		icon: 'Weebly',
	},
	{
		id: 'php',
		name: 'PHP 8 (LAMP)',
		icon: 'PHP',
	},
	{
		id: 'nginx',
		name: 'Nginx',
		icon: 'Joomla',
	},
	{
		id: 'django',
		name: 'Django',
		icon: 'Modx',
	},
];

export default applications;
